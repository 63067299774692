import { styled } from '@mui/material';
import React from 'react';
import theme from '../gatsby-theme-material-ui-top-layout/theme';

const ContentHtmlWrapper = styled('div')({
  ...theme.typography,
});

export const ContentHtml = ({ html }: { html: string }) => {
  return (
    <>
      <ContentHtmlWrapper dangerouslySetInnerHTML={{ __html: html }} />
    </>
  );
};
