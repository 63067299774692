enum TranslationStringKey {
  'Download PDF' = 'Download PDF',
  'This page is not available in your language.' = 'This page is not available in your language.',
}

export const translatedStrings = {
  en: {
    [TranslationStringKey['Download PDF']]: 'Download PDF',
    [TranslationStringKey['This page is not available in your language.']]:
      'This page is not available in your language.',
  },
  fr: {
    [TranslationStringKey['Download PDF']]: 'Télécharger PDF',
    [TranslationStringKey[
      'This page is not available in your language.'
    ]]: `Cette page n'est pas disponible dans votre langue.`,
  },
};

export const getTranslatedString = (
  locale: string,
  defaultLocale: string,
  messageKey: keyof typeof TranslationStringKey
): string => {
  const translations =
    translatedStrings[locale] || translatedStrings[defaultLocale];
  return translations[messageKey];
};
