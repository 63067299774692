import React, { ReactNode } from 'react';
import { graphql, PageProps } from 'gatsby';
import { PageLayout } from '../components/PageLayout';
import { ContentHtml } from '../components/ContentHtml';
import {
  CountryReportTranslationQuery,
  CountryReportTranslationQueryVariables,
} from '../../graphql-types';
import { useAppDispatch } from '../hooks';
import { setDefaultLocale, setLocale } from '../store/i18n';
import { Head } from '../components/Head';
import { Header } from '../components/Header';
import { ContentContainer } from '../components/ContentContainer';
import { PdfDownloadLink } from '../components/PdfDownloadLink';
import { GlobalStyles } from '../components/GlobalStyles';
import { Typography } from '@mui/material';
import { MissingTranslationBanner } from '../components/MissingTranslationBanner';

export const countryReportTranslationQuery = graphql`
  query CountryReportTranslation($id: String!, $countryCode: String!) {
    siteSettings: file(
      sourceInstanceName: { eq: "settings" }
      name: { eq: "site" }
    ) {
      childMarkdownRemark {
        frontmatter {
          defaultLocale
        }
      }
    }
    languages: allLanguagesYaml {
      nodes {
        locale
      }
    }
    countryReportTranslation: markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        locale
      }
    }
    countryReportTranslations: allFile(
      filter: {
        childMarkdownRemark: {
          frontmatter: { countryCode: { eq: $countryCode } }
        }
        sourceInstanceName: { eq: "countryReportTranslations" }
        extension: { eq: "md" }
      }
    ) {
      nodes {
        childMarkdownRemark {
          fields {
            slug
          }
          frontmatter {
            locale
          }
        }
      }
    }
  }
`;

export const CountryReportTranslationTemplate = ({
  title,
  content,
  children,
  isMissingTranslation,
}: {
  title: string;
  content: JSX.Element;
  children?: ReactNode;
  isMissingTranslation?: boolean;
}) => {
  return (
    <>
      <ContentContainer>
        {isMissingTranslation ? <MissingTranslationBanner /> : null}
        <Typography variant="h1">{title}</Typography>
        {children ? children : null}
        {content}
      </ContentContainer>
    </>
  );
};

const CountryReportTranslation = ({
  data,
  location,
  pageContext,
}: PageProps<
  CountryReportTranslationQuery,
  CountryReportTranslationQueryVariables & { locale: string }
>) => {
  const dispatch = useAppDispatch();

  const {
    html,
    frontmatter: { title },
  } = data.countryReportTranslation;

  const { locale } = pageContext;

  dispatch(setLocale(locale));
  const defaultLocale =
    data.siteSettings.childMarkdownRemark.frontmatter.defaultLocale;
  dispatch(setDefaultLocale(defaultLocale));

  const currentPath = location.pathname;
  const pathsByLocale = new Map(
    data.languages.nodes.map(({ locale }) => {
      const defaultTranslation = data.countryReportTranslations.nodes.find(
        (countryReportTranslation) =>
          countryReportTranslation.childMarkdownRemark.frontmatter.locale ===
          defaultLocale
      );

      const translation =
        data.countryReportTranslations.nodes.find(
          (countryReportTranslation) =>
            countryReportTranslation.childMarkdownRemark.frontmatter.locale ===
            locale
        ) || defaultTranslation;

      return [locale, translation.childMarkdownRemark.fields.slug];
    })
  );

  const isMissingTranslation =
    locale !== data.countryReportTranslation.frontmatter.locale;

  return (
    <>
      <GlobalStyles />
      <Head pageTitle={title} currentPath={currentPath} />
      <Header currentPath={currentPath} pathsByLocale={pathsByLocale} />
      <PageLayout>
        <CountryReportTranslationTemplate
          title={title}
          content={<ContentHtml html={html} />}
          isMissingTranslation={isMissingTranslation}
        >
          <PdfDownloadLink title={title} currentPath={currentPath} />
        </CountryReportTranslationTemplate>
      </PageLayout>
    </>
  );
};

export default CountryReportTranslation;
