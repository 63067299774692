import { Box } from '@mui/material';
import React from 'react';
import {
  colorBlueLight,
  colorRed,
} from '../gatsby-theme-material-ui-top-layout/theme';
import { useAppSelector } from '../hooks';
import { getTranslatedString } from '../i18n';
import styled from '@emotion/styled';

export const MissingTranslationBanner = () => {
  const { locale, defaultLocale } = useAppSelector((state) => state.i18n);

  const MissingTranslationBannerWrapper = styled.div`
    @media print {
      display: none;
    }
  `;

  return (
    <MissingTranslationBannerWrapper
      style={{
        backgroundColor: colorBlueLight,
        borderColor: colorRed,
        borderStyle: 'solid',
        borderWidth: '4px',
        padding: '1rem',
        marginBottom: '2rem',
      }}
    >
      {getTranslatedString(
        locale,
        defaultLocale,
        'This page is not available in your language.'
      )}
    </MissingTranslationBannerWrapper>
  );
};
