import React from 'react';
import { Link } from 'gatsby-theme-material-ui';
import { colors } from '@mui/material';
import { useAppSelector } from '../hooks';
import slugify from 'slugify';
import styled from '@emotion/styled';
import { normalizePath } from '../lib/paths';
import { getTranslatedString } from '../i18n';

interface PdfDownloadLinkProps {
  title: string;
  currentPath: string;
}

export const PdfDownloadLink = ({
  title,
  currentPath,
}: PdfDownloadLinkProps) => {
  const { locale, defaultLocale } = useAppSelector((state) => state.i18n);

  const PdfDownloadLinkWrapper = styled.div`
    @media print {
      display: none;
    }
  `;

  const href = `${normalizePath(currentPath)}/document.pdf`;
  const filename = `${slugify(title, { locale, lower: true })}_${locale}.pdf`;

  return (
    <PdfDownloadLinkWrapper>
      <Link
        href={href}
        download={filename}
        sx={{
          typography: 'h6',
          textDecoration: 'none',
          color: colors.common.black,
        }}
      >
        {getTranslatedString(locale, defaultLocale, 'Download PDF')}
      </Link>
    </PdfDownloadLinkWrapper>
  );
};
